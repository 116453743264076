function isElementOverflowing(element) {

    var overflowX = element.offsetWidth < element.scrollWidth,
        overflowY = element.offsetHeight < element.scrollHeight;
    console.log(overflowX + '+' + overflowY)
    return (overflowX || overflowY);
}

function wrapContentsInMarquee(element) {

    var marquee = document.createElement('marquee'),
        contents = element.innerText;

    marquee.innerText = contents;
    element.innerHTML = '';
    element.appendChild(marquee);
}

var element = document.getElementsByClassName('overmarquee');



for (let i = 0; i < element.length; i++) {
    // element = element[i];
    console.log(element[i]);
    if (!element[i]) {
        break;

    }
    if (isElementOverflowing(element[i])) {
        wrapContentsInMarquee(element[i]);

    }


}

//  short hand 
var byId = function (id) { return document.getElementById(id); };

// check login

function loginCheck() {
    if (!store.get('motap_prime_data')) {
        location.replace('./login.html')
    }
}

// logout function

function logOut() {
    store.remove('motap_prime_data')
    location.replace('./login.html')
}

// function for updating user name in header

function fetch_header_data() {

    //setting OW_NAME_TEXT
    OW_NAME_TEXT = document.getElementsByClassName('OW_NAME_TEXT')
    // console.log(OW_NAME_TEXT);
    i = 0
    while (OW_NAME_TEXT.length > i) {
        OW_NAME_TEXT[i].innerHTML = store.get('motap_prime_data').OW_NAME
        i++;
    }
    header_profile_img = byId('header_profile_img')

    header_profile_img_link = store.get('motap_prime_data').LOGO_LINK

    header_profile_img.src = header_profile_img_link


}

function d_none(div_id, status) {

    if (status == true) {
        div_id.classList.add('d-none')
    }
    if (status == false) {
        div_id.classList.remove('d-none')
    }

}

function bootstrapAlert(div_id, msg, type, seconds) {


    div_id.innerHTML = `<div class="alert alert-${type}" role="alert">
        ${msg}
      </div>`


    const myTimeout = setTimeout(() => {
        div_id.innerHTML = ''
    }, seconds * 1000);

}

function btn_loading(btn, msg, status) {

    btn_id = btn.id
    if (status == true) {
        btn.disabled = true;
        old_data = btn.innerHTML
        btn.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        ${msg}
        <span id='${btn_id + 'old_data'}' class='d-none'>${old_data}</span>`
    }
    if (status == false) {
        btn.disabled = false;
        old_data_div = byId(btn_id + 'old_data')
        old_data = old_data_div.innerHTML
        btn.innerHTML = `${old_data}`
    }


}
